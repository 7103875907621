console.log("AADAMITE 0.0.1")

import './css/styles.css';

// #region ERROR & PERFORMANCE
// Sentry.onLoad(function() {
//     Sentry.init({
//       // Performance Monitoring
//       tracesSampleRate: 0.01, // Capture 100% of the transactions
//       // Session Replay
//       replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//       replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

//       beforeSend(event) {
//         if (window.location.hostname === 'localhost') {
//           console.log("ERROR HERE: -", event.exception)
//           console.log("EVENT:", event.exception)
//           return null
//         }
//         return event;
//       },
//     })
//   });
// #endregion


// #region NAVBAR
  window.onscroll = function () { adjustNavBar(); };
  let topBar = document.getElementById("top-bar")
  let navbar = document.getElementById("navbar1");
  let navbarCollapse = document.getElementById("navbarCollapse");
  let offset = document.getElementById("navbarOffset")
  let accentLine = document.getElementById("accent-line")
  var rect = topBar.getBoundingClientRect()
  var sticky = rect.height;
  
  function adjustNavBar() {
      if (window.scrollY >= sticky) {
          navbar.classList.add("sticky");
          navbarCollapse.classList.add("justify-content-between")
          navbarCollapse.classList.remove("justify-content-center")
          offset.classList.remove('gone')
          accentLine.classList.add('gone')
          $('.nav-bar').addClass('nav-sticky');
          $('.about, .section-header').css("margin-top", "30px")
      } else {
          navbar.classList.remove("sticky");
          navbarCollapse.classList.remove("justify-content-between")
          navbarCollapse.classList.add("justify-content-center")
          offset.classList.add('gone')
          accentLine.classList.remove('gone')
          $('.nav-bar').removeClass('nav-sticky');
          $('.about, .page-header').css("margin-top", "0");
      }
  }
  // #endregion  


// #region ANIMATIONS
AOS.init();
// #endregion


// #region PRODUCTS
window.addEventListener('load', function() {
  const logosContainer = document.querySelector('.product-supplier-logos');
  const logos = logosContainer.innerHTML;
  logosContainer.innerHTML += logos; // Duplicate logos to create infinite scroll effect

  const logoScrollContainer = document.createElement('div');
  logoScrollContainer.className = 'product-logo-scroll';
  logoScrollContainer.innerHTML = logosContainer.innerHTML;
  logosContainer.innerHTML = '';
  logosContainer.appendChild(logoScrollContainer);
});
//#endregion

// #region CAROUSEL
// $('.carousel').carousel({
//   interval: 3000
// })
// // #endregion
// // #region COUNTERS
// function setupCounters() {
//   const counterUp = window.counterUp.default
//   const callback = entries => {
//     entries.forEach( entry => {
//       const el = entry.target
//       if ( entry.isIntersecting && ! el.classList.contains( 'is-visible' ) ) {
//         counterUp( el, {
//           duration: 1000,
//           delay: 16,
//         } )
//         el.classList.add( 'is-visible' )
//       }
//     } )
//   }
  
//   const IO = new IntersectionObserver( callback, { threshold: 1 } )
//   const counters = document.querySelectorAll('.counter')
//   counters.forEach((counter) => {
//     IO.observe(counter)
//   })
// }
// #endregion



// // #region FACTS
// async function getFacts() {
//   const docRef = doc(db, "website", "homepage");
//   const docSnap = await getDoc(docRef)

//   if (!docSnap.exists()) {
//     const err = Error("No Homepage Doc Exists")
//     Sentry.captureException(err);
//       return
//   }

//   const data = docSnap.data()
//   const facts = data.facts
//   const yearsInBusiness = facts.yearsInBusiness
//   const onTimeCompletion = facts.onTimeCompletionPercentage
//   const projectsCompleted = facts.projectsCompleted
//   const customerSatisfaction = facts.customerSatisfactionPercentage


//   document.getElementById('yearsInBusiness').childNodes[0].textContent = `${yearsInBusiness} Years`
//   document.getElementById('onTimeCompletion').childNodes[0].textContent = `${onTimeCompletion} %`
//   document.getElementById('projectsCompleted').childNodes[0].textContent = `${projectsCompleted}`
//   document.getElementById('customerSatisfaction').childNodes[0].textContent = `${customerSatisfaction} %`

//   setupCounters()
// }
// // #endregion

// // #region GALLERY
// var swiper = new Swiper(".mySwiper", {
//   loop: true,
//   spaceBetween: 4,
//   slidesPerView: 10.5,
//   freeMode: true,
//   watchSlidesProgress: true,
//   // centeredSlides: true,
//   // slideToClickedSlide: true,
// });
// var swiper2 = new Swiper(".mySwiper2", {
//   loop: true,
//   slidesPerView: 1.8,
//   centeredSlides: true,
//   spaceBetween: 0,
//   grabCursor: true,
//   effect: "coverflow",
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
//   thumbs: {
//     swiper: swiper,
//   },
//   autoplay: {
//     delay: 2000,
//     disableOnInteraction: true
//   },
//   coverflowEffect: {
//     rotate: 60,
//     stretch: 0,
//     depth: 0,
//     modifier: 1,
//     slideShadows: false,
//   },
// });
// // #endregion

// // #region TEAM
// async function getTeam() {
//   var teams = []
//   const querySnapshot = await getDocs(collection(db, "website/individualItems/teams"));
//   querySnapshot.forEach((doc) => {
//       const data = doc.data()
//       const name = data.name
//       const description = data.bio
//       const picFileName = data.picFileName
//       const uid = doc.id
//       const team = { name: name, description: description, picFileName: picFileName, uid: uid }
//       teams.push(team)
//   })

//   const teamDataElement = document.querySelector('.team-parent');
//   teams.map(item => {
//       const id = `${item.uid}`
//       var source = "./assets/images/team-placeholder.png"
//       if (item.picFileName != "" && item.picFileName != null && item.picFileName != undefined) {
//           source = `https://storage.googleapis.com/wickedinfinitypublishing.appspot.com/teamPhotos/${item.picFileName}`
//       }

//       teamDataElement.insertAdjacentHTML('afterbegin', `
//       <div class="col col-md-4" role="listitem">
//       <div class="team-item team-children" id="${id}">
//       <div class="team-img">
//       <img src=${source} alt="team Image for ${item.name}">
//       </div>
//       <div class="team-title">
//       <h3>${item.name}</h3>
//       </div>
//       <div class="team-text">
//       <p>
//       ${item.description}
//       </p>
//       </div>
//       </div>
//       </div>
//       `)
//   })
// }

// // #endregion

// // #region TESTIMONIALS

// async function getTestimonials() {
//   return
// }


// var swiper3 = new Swiper(".testimonialSwiper", {
//   loop: true,
//   slidesPerView: 1,
//   centeredSlides: true,
//   spaceBetween: 0,
//   grabCursor: true,
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
//   autoplay: {
//     delay: 4000,
//     disableOnInteraction: true
//   },
//   coverflowEffect: {
//     rotate: 60,
//     stretch: 0,
//     depth: 0,
//     modifier: 1,
//     slideShadows: false,
//   },
// });
// // #endregion


// // #region EVENTS
// async function getEvents() {
//   var events = []
//   const querySnapshot = await getDocs(collection(db, "events"));
//   querySnapshot.forEach((doc) => {
//       const data = doc.data()
//       const name = data.name
//       const description = data.description
//       const picFileName = data.picFileName
//       const uid = doc.id
//       const event = { name: name, description: description, picFileName: picFileName, uid: uid }
//       events.push(event)
//   })

//   const eventDataElement = document.querySelector('.events-parent');
//   events.map(item => {
//       const id = `${item.uid}`
//       var source = "img/event-placeholder.jpg"
//       if (item.picFileName != "" && item.picFileName != null && item.picFileName != undefined) {
//           source = `https://storage.googleapis.com/wickedinfinitypublishing.appspot.com/eventPhotos/${item.picFileName}`
//       }

//       eventDataElement.insertAdjacentHTML('afterbegin', `
//       <div class="col col-md-4" role="listitem">
//       <div class="events-item events-children" id="${id}">
//       <div class="events-img">
//       <img src=${source} alt="Event Image for ${item.name}">
//       </div>
//       <div class="events-title">
//       <h3>${item.name}</h3>
//       </div>
//       <div class="events-text">
//       <p>
//       ${item.description}
//       </p>
//       </div>
//       </div>
//       </div>
//       `)
//   })
// }

// // #endregion

// // #region CONTACT FORM
// $('#contactForm').submit(function(event) {
//   event.preventDefault();

//   var formData = $(this).serialize();
//   submitForm(formData);

//   return false
// });

// //FIXME: -  need the php form 
//  function submitForm(formData) {
//   // need to parse form data, then pipe to PHP,
//   //need to adjust result element (below) css 
//   console.log(formData)
//   return
//   // var name = document.getElementById('name')
//   // var email = document.getElementById('email')
//   // var message = document.getElementById('message')
//   // var phone = document.getElementById('phone')

//   // if (!email.value && !phone.value) {
//   //     alert('Please provide either an email or phone number.');
//   //     return
//   // }

//   // var formData = new FormData();
//   // formData.append('name', name.value);
//   // formData.append('email', email.value);
//   // formData.append('message', message.value);
//   // formData.append('phone', phone.value);

//   var formElement = document.getElementById('contactForm');
//   var resultElement = document.getElementById('result');

//   formElement.classList.add('hidden');
//   resultElement.innerHTML = 'Submitting...';

//   var xhr = new XMLHttpRequest();
//   xhr.open('POST', 'sendEmail.php', true);
//   xhr.onload = function () {
//       if (xhr.status === 200) {
//           resultElement.innerHTML = 'Form submitted successfully!';
//       } else {
//           resultElement.innerHTML = 'An error occurred. Please try again.';
//           formElement.classList.remove('hidden');
//       }
//   };
//   xhr.send(formData);
// }
// // #endregion